










import { Component, Vue, Watch, ProvideReactive } from 'vue-property-decorator';
import FlowViewer from '@/views/home/flow2/flow-viewer.vue';
import StorageUtil from 'global-ui/packages/utils/StorageUtil';

@Component({
  name: 'UserHomeFlowDone',
  components: {
    FlowViewer
  }
})
export default class UserHomeFlowDone extends Vue {
    //点击流程节点 右侧属性配置
  @ProvideReactive() flowGraphDisable: any = 'top'; //top--表示从顶级禁用  partEnable--从组件中定义：表示挂载在该组件下的‘部分’启用

  operation: string = 'task_completed';
  isShow: boolean = false;

  get _taskDataId() {
    return this.$route.query.id;
  }

  private created(): void {
    if (this.$route.query.token) {
      StorageUtil.setSessionStorage('token', this.$route.query.token);
    }
    this.$nextTick(() => {
      this.isShow = true;
    });
  }

  @Watch('_taskDataId')
  taskDataIdWatcher(val) {
    this.isShow = false;
    this.$nextTick(() => {
      this.isShow = true;
    });
  }
}
